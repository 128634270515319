import "./about.scss";
import one from "../../assets/1.png";
import two from "../../assets/2.png";
import three from "../../assets/3.png";
import four from "../../assets/4.png";
import five from "../../assets/5.png";
import six from "../../assets/6.png";
import name_newari from "../../assets/newari-name.png";

// import resumeYunika from "../../assets/resumeYunika.pdf";
import React, { useState, useEffect } from "react";
import AnimatedLetters from "../animatedLetters/AnimatedLetters";
// import p5 from "p5";

const About = () => {
  const [letterClass, setLetterClass] = useState("text-animate");

  useEffect(() => {
    const timer = setTimeout(() => {
      setLetterClass("text-animate-hover");
    }, 3000);
  }, []);

  // let sketch = (p) => {
  //   p.setup = () => {
  //     let canvasContainer = p.select("#canvas-container");
  //     let canvas = p.createCanvas(
  //       canvasContainer.width,
  //       canvasContainer.height
  //     );
  //     canvas.position = (0, 0);
  //     canvas.parent("canvas-container");
  //   };

  //   p.draw = () => {
  //     p.strokeWeight(110);
  //     if (p.mouseIsPressed === true) {
  //       p.line(p.mouseX, p.mouseY, p.pmouseX, p.pmouseY);
  //     }
  //   };

  //   p.windowResized = () => {
  //     let canvasContainer = p.select("#canvas-container");
  //     p.resizeCanvas(canvasContainer.width, canvasContainer.height);
  //   };
  // };

  // let myP5 = new p5(sketch);
  return (
    <section className="about section" id="about">
      <h2 className="section__title about__title">
        <AnimatedLetters
          letterClass={letterClass}
          strArray={["A", "b", "o", "u", "t", " ", "M", "e"]}
          idx={15}
        />
      </h2>

      <span className="section__subtitle about__subtitle">
        {" "}
        <img
          src={name_newari}
          alt="My Introduction"
          className="about_name_newari"
        />
      </span>

      <div className="about__container container grid">
        <div className="stage-cube-cont">
          <div className="cubespinner">
            <div className="face1">
              <img src={one} alt="" className="about__img" />
            </div>
            <div className="face2">
              <img src={two} alt="" className="about__img" />
            </div>
            <div className="face3">
              <img src={three} alt="" className="about__img" />
            </div>
            <div className="face4">
              <img src={four} alt="" className="about__img" />
            </div>
            <div className="face5">
              <img src={five} alt="" className="about__img" />
            </div>
            <div className="face6">
              <img src={six} alt="" className="about__img" />
            </div>
          </div>
        </div>

        <div className="about__data">
          {/* <p className="about__description" id="canvas-container"> */}
          <p className="about__description">
            <br />
            I'm a Machine Learning Engineer at REG-1, focusing on Natural
            Language Processing. I recently graduated in Computer Engineering
            from Tribhuvan University, Pulchowk Campus. Alongside my technical
            projects, I also love organizing and participating in community
            events.
            <br />
            <br />
            Apart from that, you might also find me burying my nose in a
            book—quite literally (a big fan of bibliosmia here) or putting my
            artistic skills to test with some painting.
          </p>
          {/* <a download="" href={resumeYunika} className="button button--flex button-about">
              Download Resume
              <svg
                  class="button__icon"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
              >
              <path
              d="M15.25 22.7502H9.25C3.82 22.7502 1.5 20.4302 1.5 15.0002V9.00024C1.5 3.57024 3.82 1.25024 9.25 1.25024H14.25C14.66 1.25024 15 1.59024 15 2.00024C15 2.41024 14.66 2.75024 14.25 2.75024H9.25C4.64 2.75024 3 4.39024 3 9.00024V15.0002C3 19.6102 4.64 21.2502 9.25 21.2502H15.25C19.86 21.2502 21.5 19.6102 21.5 15.0002V10.0002C21.5 9.59024 21.84 9.25024 22.25 9.25024C22.66 9.25024 23 9.59024 23 10.0002V15.0002C23 20.4302 20.68 22.7502 15.25 22.7502Z"
              fill= "var(--special-color)"></path>
              <path
              d="M22.25 10.7502H18.25C14.83 10.7502 13.5 9.42023 13.5 6.00023V2.00023C13.5 1.70023 13.68 1.42023 13.96 1.31023C14.24 1.19023 14.56 1.26023 14.78 1.47023L22.78 9.47023C22.99 9.68023 23.06 10.0102 22.94 10.2902C22.82 10.5702 22.55 10.7502 22.25 10.7502ZM15 3.81023V6.00023C15 8.58023 15.67 9.25023 18.25 9.25023H20.44L15 3.81023Z"
              fill="var(--special-color)"
              ></path>
              <path
              d="M13.25 13.7502H7.25C6.84 13.7502 6.5 13.4102 6.5 13.0002C6.5 12.5902 6.84 12.2502 7.25 12.2502H13.25C13.66 12.2502 14 12.5902 14 13.0002C14 13.4102 13.66 13.7502 13.25 13.7502Z"
              fill="var(--special-color)"
              ></path>
              <path
              d="M11.25 17.7502H7.25C6.84 17.7502 6.5 17.4102 6.5 17.0002C6.5 16.5902 6.84 16.2502 7.25 16.2502H11.25C11.66 16.2502 12 16.5902 12 17.0002C12 17.4102 11.66 17.7502 11.25 17.7502Z"
              fill="var(--special-color)"
              ></path>
              </svg>
            </a> */}

          {/* <a href="#project" className="button button--flex button-about">
            My Projects
            <svg
              class="button__icon"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M15.25 22.7502H9.25C3.82 22.7502 1.5 20.4302 1.5 15.0002V9.00024C1.5 3.57024 3.82 1.25024 9.25 1.25024H14.25C14.66 1.25024 15 1.59024 15 2.00024C15 2.41024 14.66 2.75024 14.25 2.75024H9.25C4.64 2.75024 3 4.39024 3 9.00024V15.0002C3 19.6102 4.64 21.2502 9.25 21.2502H15.25C19.86 21.2502 21.5 19.6102 21.5 15.0002V10.0002C21.5 9.59024 21.84 9.25024 22.25 9.25024C22.66 9.25024 23 9.59024 23 10.0002V15.0002C23 20.4302 20.68 22.7502 15.25 22.7502Z"
                fill="var(--special-color)"
              ></path>
              <path
                d="M22.25 10.7502H18.25C14.83 10.7502 13.5 9.42023 13.5 6.00023V2.00023C13.5 1.70023 13.68 1.42023 13.96 1.31023C14.24 1.19023 14.56 1.26023 14.78 1.47023L22.78 9.47023C22.99 9.68023 23.06 10.0102 22.94 10.2902C22.82 10.5702 22.55 10.7502 22.25 10.7502ZM15 3.81023V6.00023C15 8.58023 15.67 9.25023 18.25 9.25023H20.44L15 3.81023Z"
                fill="var(--special-color)"
              ></path>
              <path
                d="M13.25 13.7502H7.25C6.84 13.7502 6.5 13.4102 6.5 13.0002C6.5 12.5902 6.84 12.2502 7.25 12.2502H13.25C13.66 12.2502 14 12.5902 14 13.0002C14 13.4102 13.66 13.7502 13.25 13.7502Z"
                fill="var(--special-color)"
              ></path>
              <path
                d="M11.25 17.7502H7.25C6.84 17.7502 6.5 17.4102 6.5 17.0002C6.5 16.5902 6.84 16.2502 7.25 16.2502H11.25C11.66 16.2502 12 16.5902 12 17.0002C12 17.4102 11.66 17.7502 11.25 17.7502Z"
                fill="var(--special-color)"
              ></path>
            </svg>
          </a> */}
          {/* </p> */}
        </div>
      </div>
    </section>
  );
};

export default About;
