import Image1 from "../../assets/blog1.png";
import Image2 from "../../assets/blog2.png";
import Image3 from "../../assets/blog3.png";
import Image4 from "../../assets/blog4.png";
import Image5 from "../../assets/blog5.png";
import Image6 from "../../assets/blog6.png";
import Image7 from "../../assets/cit.jpg";

export const Data = [
  {
    id: 7,
    image: Image7,
    title: "Children in Technology: Dawn in The Eastern Plains",
    link: "https://medium.com/zerone-magazine/children-in-technology-dawn-in-the-eastern-plains-f9b3d0457ca0",
    description:
      "As we entered the school, a hum of curiosity echoed off the yellow walls, decorated with lively paintings...",
  },
  {
    id: 6,
    image: Image6,
    title:
      "Innovating for a Better Future: In Conversation with Bishesh Khanal",
    link: "https://medium.com/zerone-magazine/innovating-for-a-better-future-in-conversation-with-bishesh-khanal-bd288a2ef15e",
    description:
      "As we began our conversation with Mr. Khanal, it was immediately clear that his journey to the forefront of...",
  },
  {
    id: 1,
    image: Image1,
    title: "Code Like Her Fellowship",
    link: "https://medium.com/code-rush-blog/code-like-her-fellowship-ii-weekly-6-32ee89b54cc1",
    description:
      "The more I dived into this language, the more I encountered its quirky traits, which managed to amaze...",
  },
  {
    id: 4,
    image: Image4,
    title: "COVID-19 through the Eyes of My Grandmother",
    link: "https://yunikabajracharya.medium.com/covid-19-through-the-eyes-of-my-grandmother-8ab560c5477b",
    description:
      "“Oh, God! How has this teeny-tiny invisible thing managed to confine humans, the mightiest of all...",
  },
  {
    id: 2,
    image: Image2,
    title: "The Metamorphosis",
    link: "https://yunikabajracharya.medium.com/the-metamorphosis-1ea8dddc2f26",
    description:
      "That morning, Gregor woke up with an unusual freshness. The sun was already beaming through his blinds...",
  },
  {
    id: 3,
    image: Image3,
    title: "Orpheus and Eurydice",
    link: "https://yunikabajracharya.medium.com/orpheus-and-eurydice-8fef38d2db0f",
    description:
      "It was a bright sunny day, the birds were chirping. The palace was all colorful and the whole kingdom...",
  },
  {
    id: 5,
    image: Image5,
    title: "(Un-)social Media",
    link: "https://medium.com/zerone-magazine/un-social-media-4702a5192076",
    description:
      "Meet the inner soliloquy that plays in our subconscious mind during an ordinary social media scroll...",
  },
];
