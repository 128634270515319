import osd from "../../assets/osd.jpg";
import Experience3 from "../../assets/33.png";
import anitaB from "../../assets/anitaB.jpg";
import gfd from "../../assets/gfd.jpg";
import git from "../../assets/git.jpg";
import sff from "../../assets/sff.jpg";
import pg from "../../assets/pg.png";
import zerone from "../../assets/zerone.png";
import weCode from "../../assets/weCode.png";
import tu from "../../assets/tu.png";
import hmv from "../../assets/hmv.png";
import swarnim from "../../assets/swarnim.png";
import kmc from "../../assets/kmc.jpg";
import codeRush from "../../assets/codeRush.png";
import aceIgnite from "../../assets/aceIgnite.png";
import revampathon from "../../assets/revampathon.jpg";
import codecamp from "../../assets/codecamp.png";
import shequal from "../../assets/shequal.png";
import hackadev from "../../assets/HackaDev.jpeg";
import gce from "../../assets/gce.png";
import beamlab from "../../assets/beamlab.jpeg";
import naamche from "../../assets/naamche.png";
import reg1 from "../../assets/reg1.jpg";
import naamii from "../../assets/naamii_logo.svg";

export const experienceData = [
  {
    image: reg1,
    title: "Machine Learning Engineer",
    company: "REG-1",
    date: "Jun 2024 - Present",
    description: (
      <ul className="ul">
        <li>
          Developing AI solutions at REG-1, including designing data pipelines,
          training, finetuning LLMs, optimizing data extraction and processing
          for regulatory documents, semantic search capabilities.
        </li>
      </ul>
    ),
  },
  {
    image: naamche,
    title: "Software Engineering Intern",
    company: "Naamche",
    date: "Jan -  Mar 2024",
    description: (
      <ul className="ul">
        <li>
          Worked on development of a chatbot, handling project bootstrapping,
          websocket integration, message streaming and rendering, deployment to
          AWS Amplify.
        </li>
        <li>
          Contributed to designing server response for displaying sources,
          ensuring effective text and table highlighting.
        </li>
      </ul>
    ),
  },
  // {
  //   image: beamlab,
  //   title: "Software Intern",
  //   company: "Beam Lab",
  //   date: "Jan -  Feb 2023",
  //   description: <ul className="ul"></ul>,
  // },
  {
    image: naamii,
    title: "4th Annual Nepal Al School (ANAIS), 2023",
    company:
      "NAAMII - Nepal Applied Mathematics and Informatics Institute for research",
    date: "May 22 - Jun 1, 2023",
    description: (
      <ul className="ul">
        Participated in the 10-day AI School that covered wide range of AI
        topics, lectures, lab sessions, along with project and professional
        development sessions.{" "}
        <a
          href="https://drive.google.com/file/d/1BE49r-Iub7eA7q5zqbQxnzlcbDMVf_P7/view"
          target="_blank"
        >
          Certificate
        </a>
      </ul>
    ),
  },
  {
    image: osd,
    title: "Open Source Day Mentor, Participant",
    company: "AnitaB.org",
    date: "Jul 2021 | Sep 16, 2022",
    description: (
      <ul className="ul">
        <li>
          <b>Open Source Day 2021 (Mentor)&emsp;</b>
          |&emsp;<button>Jul, 2021</button>
          <br />
          Mentored participants on using git, GitHub, setting up the project{" "}
          <a
            href="https://github.com/smaranjitghose/awesome-portfolio-websites"
            target="_blank"
          >
            Awesome Portfolio Websites
          </a>{" "}
          and helped them make their first open source contributions.
        </li>
        <li>
          <b>Open Source Day 2022 (GHC Scholar)&emsp;</b>
          |&emsp;<button>Sep 16, 2022</button>
          <br />
          Contributed to{" "}
          <a href="https://github.com/numpy/numpy" target="_blank">
            NumPy
          </a>{" "}
          documentation.
        </li>
      </ul>
    ),
  },

  {
    image: codeRush,
    title: "Code Like Her Fellowship",
    company: "Code Rush",
    date: "Dec 2021 - Mar 2022",
    description: (
      <ul className="ul">
        <li>Selected as one of the 15 fellows.</li>
        <li>
          <b>Skills:</b> HTML, CSS, JavaScript, NodeJS, Express, React.js
        </li>
        <li>
          <b>Projects:</b>{" "}
          <a
            href="https://github.com/Coderushnepal/YunikaBajracharya"
            target="_blank"
          >
            Click Here{" "}
          </a>
          &#x2B29;
          <a href="https://medium.com/code-rush-blog/code-like-her-fellowship-ii-weekly-6-32ee89b54cc1">
            {" "}
            Blog
          </a>
        </li>
      </ul>
    ),
  },
  {
    image: sff,
    title: "Seeds for the Future 2021",
    company: "Huawei",
    date: "Nov - Dec 2021",
    description: (
      <ul className="ul">
        <li>
          Led the team project ‘Tech4Good’ on tracking the insulin dose and
          glucometer readings for Diabetic patients.
        </li>
        <li>Learned fundamental courses on 5G, Cloud Computing, and AI</li>
        <li>
          Visited 4G base station in Ghandruk and attended sessions on
          leadership, tech talks, cultural exchange.
        </li>
        <li>
          Project Details:
          <a href="https://sathi-app.github.io/" target="_blank">
            {" "}
            Website{" "}
          </a>
          &#x2B29;
          <a
            href="https://www.youtube.com/watch?v=mxnGCWSWLTY&list=PLfEevmzbs5mMKyBsHwh8c73v6xC60i4eX&index=4"
            target="_blank"
          >
            {" "}
            Intro Video{" "}
          </a>
          &#x2B29;
          <a
            href="https://www.youtube.com/watch?v=ij6_imqs3Nc&list=PLfEevmzbs5mMKyBsHwh8c73v6xC60i4eX&index=4"
            target="_blank"
          >
            {" "}
            Demo Video
          </a>
        </li>
        <li>
          <a
            href="https://www.youtube.com/watch?v=k4MR0XnlJ_I&list=PLfEevmzbs5mMKyBsHwh8c73v6xC60i4eX&index=1"
            target="_blank"
          >
            Student Representative
          </a>
        </li>
      </ul>
    ),
  },
];

export const volunteeringData = [
  {
    image: gce,
    title: "GitHub Campus Expert",
    company: "GitHub",
    date: "Nov 2022 - Present",
    description: (
      <p className="ul">
        GitHub Campus Experts are student leaders that strive to build diverse
        and inclusive spaces to learn skills, share their experiences, and build
        projects together. View my{" "}
        <a
          href="https://githubcampus.expert/Yunika-Bajracharya/"
          target="_blank"
        >
          Profile.
        </a>
        <br />
        <small> Mentored Campus Expert Trainee - Aug 2023 Batch</small>
      </p>
    ),
  },
  {
    image: zerone,
    title: "Editor-in-Chief",
    company: "The Zerone",
    date: "June 2023 - March 2024",
    description: (
      <ul className="ul">
        <li>
          <b>Editor-in-Chief&emsp;</b>
          |&emsp;<button>The Zerone 2024</button>
          <br />➯ Led the team in producing the 14th issue of{" "}
          <a href="https://www.instagram.com/thezeronemag/" target="_blank">
            The Zerone Magazine
          </a>
          , the annual undergrad tech magazine at IOE, Pulchowk Campus. <br />➯
          Introduced a new chapter, <i>Zerone Scholar</i>, showcasing student
          research in Electronics, Computer, and Electrical Engineering.
          <br />➯ Organized article writing competition and interview series
          with tech professionals in Nepal.
          <br />
        </li>
        <li>
          <b>Interview Head&emsp;</b>
          |&emsp;<button>The Zerone 2023</button>
          <br />
          Interviewed prominent tech professionals for the magazine.
        </li>
        <li>
          <b>Associate Editor&emsp;</b>
          |&emsp;<button>The Zerone 2021</button>
          <br />
          Led the team for grammar-related social media posts, wrote and edited
          articles for the magazine.
        </li>
        <li>
          <b>Editor&emsp;</b>
          |&emsp;<button>The Zerone 2020</button>
          <br />
          Wrote, edited articles for the magazine, writing competitions and
          designed posters for writing sessions.
        </li>
      </ul>
    ),
  },

  {
    image: Experience3,
    title: "Instructor",
    company: "Workshops & Traning Programs",
    date: "",
    description: (
      <ul className="ul">
        <li>
          <b>Girls to Code, Mentor&emsp;</b>
          |&emsp;<button>Dec 29 - 31, 2023</button>
          <br />
          Mentored the participants during the 3-day workshop on Front-end
          Development with ReactJS.
        </li>
        <li>
          <b>Children in Technology&emsp;</b>
          |&emsp;<button>Nov 24 - 28, 2023</button>
          <br />
          With a team of 13, we visited the easternmost part of Nepal to raise
          awareness on essential technology topics, including hardware,
          software, internet safety, and cybercrime. We conducted the sessions
          in 13 government schools across Koshi and Madesh Pradesh, covering
          five districts: Dharan, Biratnagar, Itahari, Inaruwa, and Janakpur.{" "}
          <tab></tab>
          <a
            href="https://medium.com/zerone-magazine/children-in-technology-dawn-in-the-eastern-plains-f9b3d0457ca0"
            target="_blank"
          >
            <u>Blog</u>
          </a>
        </li>
        <li>
          <b>RoboCamp&emsp;</b>
          |&emsp;<button>July 16 - 22, 2023</button>
          <br />
          Mentored students at RoboCamp, organized by Robotics Club, IOE
          Pulchowk Campus.
        </li>
        <li>
          <b>Software Fellowship&emsp;</b>
          |&emsp;<button>June 25, 2023</button>
          <br />
          Instructed students on Object Oriented Programming using Python during
          Software Fellowship, organized by LOCUS 2023.
        </li>
        <li>
          <b>Version Control System with Git&emsp;</b>
          |&emsp;<button>Jul 12 - Jul 15, 2022</button>
          <br />
          Instructed students on git and version control in a 4-day workshop
          organized by IT Club, Pulchowk.
        </li>
        <li>
          <b>Microsoft Bootcamp Trainer, Girls in Tech–Nepal&emsp;</b>
          |&emsp;<button>Apr - Jun, 2022</button>
          <br />
          Instructor for Microsoft Office training given to students of Center
          for Disabled Children Assistance (CDCA), Dhanyawaad, and Tara Namaste
          Orphanage.
        </li>
      </ul>
    ),
  },
  {
    image: pg,
    title: "Technical Manager",
    company: "Pulchowk Girls",
    date: "Jun 2022 - Jun 2023",
    description: (
      <ul className="ul">
        <li>Manage technical aspects and design posters of the events.</li>
      </ul>
    ),
  },
  {
    image: gfd,
    title: "Communication and Outreach Lead",
    company: "GitHub Field Day Nepal",
    date: "Apr - Jun 2022",
    description: (
      <>
        GitHub Field Day is an unconference for leaders of technical student
        communities. Field day Nepal 2022 brought together 50 student leaders
        from 12 cities representing 40+ tech communities.
        <br />
        <br />
        <ul className="ul-small-gap">
          <li>One of the organizers for the first GitHub Field Day Nepal.</li>
          <li>
            Reached out to 50+ tech communities, media channels and IT colleges
            across the country.
          </li>
          <li>
            Responsible for communication with keynote speaker, campus experts
            and participants.
          </li>
          <li>Moderated Group Discussions during the Field Day.</li>
        </ul>
      </>
    ),
  },
  {
    image: git,
    title: "Outreach Coordinator Ambassador",
    company: "Girls in Tech-Nepal",
    date: "Jan 2021 - Jun 2023",
    description: (
      <>
        <ul className="ul-small-gap">
          <li>
            Reached out to speakers for{" "}
            <a
              href="https://www.youtube.com/watch?v=nKxwriaQxO0&list=PLrC-5k2StDOiqFW3ssT_tnStQG88SYVNW&index=4"
              target="_blank"
            >
              Her Journey
            </a>{" "}
            series and webinars.
          </li>
          <li>Moderated webinars and promote GIT-Nepal.</li>
          <li>Trainer for Microsoft Office Bootcamp</li>
        </ul>
      </>
    ),
  },
  {
    image: hmv,
    title: "Online Women Empowerment Program",
    company: "Hear My Voice - Women's Network",
    date: "May - Jun 2020",
    description: (
      <ul className="ul">
        <li>
          Discussed women's rights and social justice with women across 7
          countries for 6 weeks.
        </li>
        <li>
          Panelist in Panel Discussion - ‘Stand Up Against Gender-Based
          Violence’ on Nov 23, 2020 to celebrate the beginning of the 16 Days of
          Activism against Gender-Based Violence.
        </li>
      </ul>
    ),
  },
];

export const scholarshipData = [
  {
    image: hackadev,
    title: "HackaDev - AI for Impact Winner",
    company: "NAAMII, DiyoAI, UNDP Accelerator Lab",
    date: "May 22 - Jun 1, 2023",
    description: "",
  },
  {
    image: shequal,
    title: "Hacking For Humanity Second Runner Up",
    company: "Shequal Foundation",
    date: "Feb 10-12, 2023",
    description: "",
  },
  {
    image: codecamp,
    title: "UXCam Code Camp First Runner Up",
    company: "Locus 2023",
    date: "Jan 28-29, 2023",
    description: "",
  },
  {
    image: revampathon,
    title: "Revampathon Hackathon Winner",
    company: "Leapfrog Student Partnership Program",
    date: "Dec 2-17, 2022",
    description: "",
  },
  {
    image: aceIgnite,
    title: "Ace Ignite 48-hour Hackathon Winner",
    company: "Ace Institute of Management",
    date: "Nov 11-13, 2022",
    description: "",
  },
  {
    image: anitaB,
    title: "Grace Hopper Celebration Scholar 2022",
    company: "AnitaB.org",
    date: "",
    description: "",
  },
  {
    image: weCode,
    title: "Harvard WECode Scholar 2022",
    company: "Harvard WECode",
    date: "",
    description: "",
  },
];

export const educationData = [
  {
    image: tu,
    title: "Tribhuvan University, Pulchowk Campus",
    company: "Bachelor in Computer Engineering ",
    date: " | 2019-2024",
    description: "",
  },
  {
    image: kmc,
    title: "Kathmandu Model Secondary School",
    company: "Grade 11, 12 ",
    date: " | 2017-2018",
    description: (
      <ul>
        <li>&#x2B29; President, KMC Art Club 2018-19</li>
        {/* <li>&#x2B29; Winner, KMC Annual Art Competition 2019</li> */}
      </ul>
    ),
  },
  {
    image: swarnim,
    title: "Swarnim School",
    company: "Grade 1-10 ",
    date: " | Graduated: 2016",
    description: (
      <ul>
        <li>&#x2B29; Head Girl, 22nd Batch</li>
      </ul>
    ),
  },
];
